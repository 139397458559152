<template>
    <div class="myNews">
        <div class="content">
            <div class="list" @click="consulation(item.consultation_id)" v-for="item in messageList" :key="item.consulation_id">
                <div class="list_info flex05">
                    <div class="user_name">{{item.name}}</div>
                    <div class="user_sex">{{item.sex}}</div>
                    <div class="user_age">{{item.age}}岁</div>
                </div>
                <div class="list_message flex05"> 
                    <div class="message_label">咨询医生：</div>
                    <div class="message_content">王医生 主任医师 北京朝阳医院</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {consultationList} from '@/api/message'
export default {
    data () {
        return {
            starPage:1,
            messageList:[]
        }
    },
    created () {
        this.getNewsList(this.starPage)
    },
    methods:{
        async getNewsList(page) {
            const res = await consultationList({page:page})
            if(res.data.code == 200) {
                this.messageList = res.data.data
            }
        },
        consulation (id) {
            this.$router.push({path:'/customer',query:{
                id:id
            }})
        }
    }
}
</script>

<style lang="less" scoped>
    .myNews {
        margin-top: 15px;
        .content {
            padding: 0 15px;
            .list {
                border-radius: 10px;
                box-shadow: 1px 1px 10px rgba(0, 96, 240, 0.1);
                padding: 20px;
                color: #999999;
                font-size: 14px;
                .list_info {
                    line-height: 30px;
                    .user_name {
                        color: #333333;
                        font-size: 18px;
                        font-weight: 600;
                    }
                    .user_sex {
                        padding-left: 10px;
                        padding-right: 10px;
                        border-right: 2px solid #ccc;
                        line-height: 20px;
                    }
                    .user_age {
                        padding-left: 10px;
                        line-height: 20px;
                    }
                }
                .list_message {
                    margin-top: 8px;
                    // .message_label {}
                    // .message_content {}
                }
            }
            .list:nth-child(n+2) {
                margin-top: 15px;
            }
        }
        .flex05 {
            display: flex;
            align-items: center;
        }
    }
</style>