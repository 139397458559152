import _fetch from "../utils/request";

export  function csResponseMsg(data) {
    return _fetch({
        url:'cs/response/msg',
        method:'post',
        data
    })
}
// 消息列表v1/rls/user/consultation/list
export  function consultationList(query) {
    return _fetch({
        url:'/v1/rls/user/consultation/list',
        method:'get',
        params: query,
        needToken:true
    })
}